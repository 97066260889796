var transform = function(editor) {
	$(editor).find('.slide-multi-shows').each(function (){
		var imgLocations = []
		$(this).find('.slide').each(function() {
			var loc = $(this).css('background-image')
			loc = loc.replace(/url\(['"]/, '').replace(/['"]\);?$/, '').replace(/http:\/\/[^\/]*/, '')
			imgLocations.push(loc)
		})
		$(this).find('.slide').remove()
		while(imgLocations.length > 0) {
			$(this).append('<img src="' + imgLocations.shift() + '" />')
		}
		
		if($(this).hasClass('transform-images') || $(this).closest('.transform-images').length > 0) {
			$(editor).find('.left-arrow').remove()
			$(editor).find('.right-arrow').remove()
		}
	})
}

module.exports = transform