var $ = require('jquery')
var resizeImg = require('./resize-img')

var tri = require('tripartite').createBlank()
tri.parseTemplateScript(require('./upload.tmpl'))

var templates = tri.templates

var addStylesIfNeeded = function() {
	if($('#pic-upload-styles').length == 0) {
		$('head').append('<style type="text/css" id="pic-upload-styles">' +
		templates['picUploadStyles']() +
		'</style>')
	}
}

var PicUpload = function(dropTarget, optionsTarget) {
	this.dropTarget = dropTarget
	if(typeof optionsTarget == 'function') {
		this.optionsTarget = optionsTarget
	}
	else {
		this.optionsTarget = function() {
			return optionsTarget
		}
	}
	
	this.resizeOptions = [{
		max: 50000,
		name: 'none'
	},
	{
		max: 1920,
		name: '1920px'
	},
	{
		max: 1024,
		name: '1024px'
	},
	{
		max: 600,
		name: '600px'
	}

	]
	
	this.imageLayouts = []
	
	this.documentImage = this.defaultDocumentImage
	this.documentInsertionImage = this.defaultDocumentInsertionImage
}

PicUpload.prototype.defaultDocumentImage = '/page-editor/img/file-icon-md.png'
PicUpload.prototype.defaultDocumentInsertionImage = '/page-editor/img/file-icon-md.png'


PicUpload.prototype.isFilenameImage = function(name) {
	if(name) {
		name = name.toLowerCase()
		return name.endsWith('.jpg') || name.endsWith('.png') || name.endsWith('.gif')
	}
	return false
}

PicUpload.prototype.isImage = function() {
	return this.isFilenameImage(this.name)
}

PicUpload.prototype.render = function() {
	addStylesIfNeeded()
	var self = this
	var $container = $(this.dropTarget)
	$container.on('dragenter', function (e) {
		if(self.hasFiles(e)) {
			e.stopPropagation()
			e.preventDefault()
			$container.addClass('about-to-drop')
		}
	});
	$container.on('dragover', function (e) {
		if(self.hasFiles(e)) {
			e.stopPropagation()
			e.preventDefault()
			$container.addClass('about-to-drop')
		}
	});
	$container.on('dragstop dragend', function (e) {
		if(self.hasFiles(e)) {
			e.stopPropagation()
			e.preventDefault()
		}
	})
	$container.on('dragleave', function (e) {
		if(self.hasFiles(e)) {
			e.stopPropagation()
			e.preventDefault()
			if($(e.target).hasClass('about-to-drop')) {
				$container.removeClass('about-to-drop')
			}
		}
	});
	var cleanFilename = function(filename) {
		return filename.split("%20").join("-").split(" ").join("-").split(",").join("-")
	}
	var readerOnLoad = function (reader, name) {
		name = cleanFilename(name)
		self.name = name
		self.img = new Image()
		var $img = $(self.img)
		$img.attr('data-filename', name)
		if(self.isFilenameImage(name)) {
			$img.attr('src', reader.result)
			
			$(self.optionsTarget()).html(templates.uploadOptions({
				resizeOptions: self.resizeOptions,
				name: name,
				imageLayouts: self.imageLayouts
			}))
		}
		else {
			$img.attr('src', self.documentImage)
			self.fileData = reader.result
			$(self.optionsTarget()).html(templates.uploadOptions({
				name: name
			}))
		}
		$(self.optionsTarget()).find('.img-holder').append($img)
	}
	
	$(document).on('paste', function(evt) {
		var items = evt.originalEvent.clipboardData.items;
		if (items) {
			var filename = 'unknown'
			var ext = ".png"
			// Search clipboard items for an image
			for (var i = 0; i < items.length; i++) { // removed: i < items.length, items[i].type.indexOf("image") !== -1
				if (items[i].type.indexOf("image") !== -1) {
					//foundImage = true; Not sure why this was here								
					// Convert image to blob using File API	               
					var blob = items[i].getAsFile();
					var reader = new FileReader();
					ext = items[i].type == 'image/png' ? '.png' : '.jpg'
					
					reader.onload = function() {
						var fullname = filename + ext
						readerOnLoad(reader, fullname)
					}
					/* Convert the blob from clipboard to base64 */		
					reader.readAsDataURL(blob);
					//foundImage = false; Not sure why this was here
				}
				else if (items[i].type.indexOf("text/html") !== -1) {
					items[i].getAsString(function(s) {
						if(s) {
							var src = $('<div>' + s + '</div>').find('img').attr('src')
							if(src) {
								var parts = src.split('/')
								filename = parts[parts.length - 1].split('.')[0]
								$(self.optionsTarget()).find('[name=name]').val(cleanFilename(filename + ext))
							}
							
						}
					})
				}
			}
		} 		
	})
	$container.on('drop', function(evt) {
		if(self.hasFiles(evt)) {
			evt.stopPropagation()
			evt.preventDefault()
			$container.removeClass('about-to-drop')
			
			var files = evt.originalEvent.target.files
					|| evt.originalEvent.dataTransfer.files
			var file = files[0]
			var reader  = new FileReader()
			
			reader.onloadend = function() {
				readerOnLoad(reader, file.name)
			}
			reader.readAsDataURL(file)

		}
	});
}

PicUpload.prototype.hasFiles = function(evt) {
	var files = evt.originalEvent.target.files
			|| evt.originalEvent.dataTransfer.files
	if(files.length > 0) {
		return true
	}
	if(evt.originalEvent.dataTransfer.items && evt.originalEvent.dataTransfer.items.length > 0) {
		if(evt.originalEvent.dataTransfer.items[0].kind == 'file') {
			return true
		}
	}
	return false
}

PicUpload.prototype.getLayoutClasses = function() {
	if(this.isImage()) {
		return $(this.optionsTarget()).find('[name=specialLayout]').val()
	}
	else {
		return ''
	}
}

PicUpload.prototype.getImageData = function() {
	if(this.isImage()) {
		var size = $(this.optionsTarget()).find('[name=resize]').val()
		return resizeImg(this.img, size)
	}
	else {
		return this.fileData
	}
}

PicUpload.prototype.getImageName = function() {
	var imageName = $(this.optionsTarget()).find('[name=name]').val()
	return imageName
}

PicUpload.prototype.getImageDescription = function() {
	var desc = $(this.optionsTarget()).find('[name=description]').val()
	return desc
}

PicUpload.prototype.cleanup = function() {
	$(document).off('paste')
	$(this.dropTarget).removeClass('about-to-drop')
}


module.exports = PicUpload