var $ = require('jquery')
var PicUpload = require('./pic-upload')
var Dialog = require('ei-dialog')
var _ = require('underscore')

var ckeditorDrop = function(id, targetDirectory) {
	this.id = id
	if(targetDirectory) {
		this.targetDirectory = targetDirectory
	}
	else {
		var prefixParts = window.location.pathname.split('/')
		prefixParts.pop()
		this.targetDirectory = '/img' + prefixParts.join('/')
	}
	this.fileUploadUrlPrefix = '/files/upload'
}

ckeditorDrop.prototype.getEditorInstance = function() {
	return CKEDITOR.instances[this.id.substring(1)]
}

ckeditorDrop.prototype.insertDocumentLink = function(url, desc) {
	var edInstance = this.getEditorInstance()
	edInstance.insertHtml('<a href="' + url + '" ><img src="' + this.picUpload.documentInsertionImage + '" /></a>')
}

ckeditorDrop.prototype.insertImage = function(url, desc) {
	var edInstance = this.getEditorInstance()
	var classes = this.picUpload.getLayoutClasses()
	edInstance.insertHtml('<img alt="' + desc + '" class="' + classes + '" src="' + url + '" />')

}

ckeditorDrop.prototype.render = function() {
	var self = this
	var uploadImage = function(data, name, doneCallback) {
		var msg = $.ajax({
			url: self.fileUploadUrlPrefix + self.targetDirectory,
			type: 'POST',
			cache: false,
			data: {
				dataUrl: data,
				dataFilename: name
			}
		})
		
		if(doneCallback) {
			msg.done(doneCallback)
		}
		
		var finalUrl = self.targetDirectory + (self.targetDirectory.endsWith('/') ? '' : '/') + name
		return finalUrl
	}

	var picUpload = new PicUpload(this.id, function() {
		
		var $diagBody = $('.dialog-frame .body')
		if($diagBody.length == 0) {
			var diag = new Dialog({
				title: 'Upload',
				body: function(container) { 
					$(container).append('<p></p>')
					return ''
				},
				on: {
					'.btn-ok': function() {
						var edInstance = CKEDITOR.instances[self.id.substring(1)]
						if(picUpload.isImage()) {
							var imageUrl = uploadImage(picUpload.getImageData(), picUpload.getImageName(), function() {
								self.insertImage(imageUrl, picUpload.getImageDescription())
								picUpload.cleanup()
								diag.close()
							})
						}
						else {
							var imageUrl = uploadImage(picUpload.getImageData(), picUpload.getImageName(), function() {
								self.insertDocumentLink(imageUrl, picUpload.getImageDescription())
								picUpload.cleanup()
								diag.close()
							})
						}
						return false
					},
					'.btn-cancel': function() {
						picUpload.cleanup()
					}
				}
			})
			diag.open()
		}
		
		
		return '.dialog-frame .body'
	})
	picUpload.imageLayouts = this.imageLayouts || []
	picUpload.render()
	this.picUpload = picUpload
	return this
}

module.exports = ckeditorDrop