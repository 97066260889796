window.require = require
window.jQuery = window.$ = require('jquery')

require('shiner')
require('./photo-lightbox')

require("slide-multi-shows")()

var slideTransform = require('slide-multi-shows/transform-images-editor-conversion')

var Tripartite = require('tripartite')
/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

var pageEditor = require('page-editor')

pageEditor.preconvertEditor = function(editor) {
	slideTransform(editor)
}

pageEditor.extraPlugins = 'widget,slide-multi-show'

pageEditor.configure()

$('.tribar').click(function() {
	$('body').toggleClass('menu-open');
})

$('.page-index .slide').shiner()