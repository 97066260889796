
if(!window.$ || !window.jQuery) {
	window.$ = window.jQuery = require('jquery')
}

require('shiner')

var activateSlideshows = function() {
	
	
	$('.slide-multi-shows.transition').each(function() {
		
		if($(this).hasClass('transform-images')) {
			var $self = $(this)
			$self.find('img').each(function () {
				$self.append('<span class="slide" style="background-image: url(\'' + $(this).attr('src') + '\');">&nbsp;</span>');
			})
			$self.find('p').remove()
			$self.find('img').remove()
			if($self.find('.left').length == 0 && $self.hasClass('arrows')) {
				$self.append('<div class="left-arrow">&nbsp;</div><div class="right-arrow">&nbsp;</div>')
			}
		}
		
		var shiner = $(this).find('.slide').shiner()
		$(this).find('.left-arrow').click(function() {
			shiner.previous()
		})
		$(this).find('.right-arrow').click(function() {
			shiner.next()
		})
		$(this).find('.stop').click(function() {
			shiner.stop()
		})
		$(this).find('.go').click(function() {
			shiner.go()
		})
	})
}

module.exports = activateSlideshows